import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { PageChange } from "../../../../../common/Scroll";
import {
  fadeIn,
  fadeOut,
  slideToLeft,
  slideToRight,
} from "../../../../../../utils/animations";
import { H4, TEXT_BODY_2 } from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 160px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h1`
  ${H4}
  margin: 0 0 48px;
  color: #013cff;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  white-space: pre-line;
  color: #151515;
`;

export const DescriptionItem = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const DescriptionText = styled.div`
  padding-left: 5px;
`;

export const ArrowsWrapper = styled.div`
  height: 51px;
  display: grid;
  grid-template-columns: repeat(50%, 2);
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
