import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../../../common/PageTitle";

import {
  PageWrapper,
  SlideTransitionWrapper,
  Title,
  DescriptionWrapper,
  Description,
  DescriptionItem,
  DescriptionText,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";

interface AboutUsProps {
  pageChange: PageChange;
}

const AboutUsMobile: React.FC<AboutUsProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");
  const descriptions = (t(`aboutUs_description`) as unknown) as string[];
  const nOfSlides = 1;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide - 1);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide + 1);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`aboutUsPage_title`))} />
      <PageWrapper animation={pageChange}>
        <Title>{t(`aboutUs_title`)}</Title>
        {slide === 0 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <DescriptionWrapper>
              <Description>
                {descriptions.slice(0, 4).map(e => (
                  <DescriptionItem>
                    &middot; <DescriptionText>{e}</DescriptionText>
                  </DescriptionItem>
                ))}
              </Description>
            </DescriptionWrapper>
          </SlideTransitionWrapper>
        ) : (
          slide === 1 && (
            <SlideTransitionWrapper slideChange={slideTransition}>
              <DescriptionWrapper>
                <Description>
                  {descriptions.slice(4, descriptions.length).map(e => (
                    <DescriptionItem>
                      &middot; <DescriptionText>{e}</DescriptionText>
                    </DescriptionItem>
                  ))}
                </Description>
              </DescriptionWrapper>
            </SlideTransitionWrapper>
          )
        )}
        <ArrowsWrapper>
          <LeftArrowWrapper
            slideChange={slide === 1 ? slideTransition : undefined}
          >
            {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
          </LeftArrowWrapper>
          <RightArrowWrapper
            slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
          >
            {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
          </RightArrowWrapper>
        </ArrowsWrapper>
      </PageWrapper>
    </>
  );
};

export default AboutUsMobile;
